@import '../variables.scss';

.Subscribe {
  background: $color-yellow;

  .subscription-box {
    border-radius: 8px;
    width: 100%;
  }

  &.disabled {
    .subscription-text {
      opacity: 0.25;
    }
  }
}
