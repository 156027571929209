@import '../../variables.scss';

.Logo {
  .jumbotron {
    background-color: $color-background;
    border-radius: 0px;
  }

  .logo {
    width: 100%;
  }

  @media (min-width: 768px) {
    .jumbotron {
      background-image: url('../../../images/blooming-flowers-sm.jpg');
      background-size: calc(100% + 175px);
      background-position: -150px -50px;
      height: $logo-height;
    }

    .content {
      width: 440px;
    }

    .logo {
      height: 300px;
    }
  }
}
