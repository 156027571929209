@import '../../../node_modules/bootstrap/scss/bootstrap';

.Signin {
  .btn-facebook {
    @include button-variant(#4267b2, #4267b2);
  }

  .btn-google {
    @include button-variant(#4285f4, #4285f4);
  }
}
