$spacer: 1rem;
$spacers: (
  -1: $spacer * -1,
  6: $spacer * 4,
  7: $spacer * 5,
  8: $spacer * 6,
  9: $spacer * 7,
  10: $spacer * 8
);

@import "bootstrap/scss/bootstrap.scss";