@import '../../variables.scss';

.Promo {
  @media (max-width: 767.98px) {
    .nav {
      &.blue,
      a.blue.active {
        border-color: darken($color-blue, 20%);
      }

      a.blue {
        background-color: $color-blue;

        &.active {
          background-color: darken($color-blue, 20%);
        }
      }

      &.green,
      a.green.active {
        border-color: darken($color-green, 20%);
      }

      a.green {
        background-color: $color-green;

        &.active {
          background-color: darken($color-green, 20%);
        }
      }

      &.yellow,
      a.yellow.active {
        border-color: darken($color-yellow, 20%);
      }

      a.yellow {
        background-color: $color-yellow;

        &.active {
          background-color: darken($color-yellow, 20%);
        }
      }

      li {
        & + li {
          a {
            margin-left: 4px;
          }
        }
      }
    }

    .promo-cards {
      border: 4px solid;
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;

      ul {
        padding-left: 20px;
      }

      &.blue {
        border-color: darken($color-blue, 20%);
      }

      &.green {
        border-color: darken($color-green, 20%);
      }

      &.yellow {
        border-color: darken($color-yellow, 20%);
      }
    }
  }
}
