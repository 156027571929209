@import '../../variables.scss';

.PromoCard {
  &.blue,
  &.blue .card-img-overlay {
    background: $color-blue;
  }
  &.green,
  &.green .card-img-overlay {
    background: $color-green;
  }
  &.yellow,
  &.yellow .card-img-overlay {
    background: $color-yellow;
  }

  img {
    height: $section-height;
    opacity: 0.8;
  }

  .card-img-overlay {
    bottom: 15%;
    opacity: 0.8;
    left: 0;
    top: 40%;
    width: 252px;

    .card-title {
      font-size: 1.1rem;
    }

    .card-text {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 767.98px) {
    margin-bottom: 0;

    &.card,
    .card-img {
      border-radius: 0;
    }
  }
}
