.Questions {
  .questions {
    background: rgba(255, 255, 255, 0.7);
    min-height: 100%;
    overflow: auto;

    .selected {
      font-weight: bolder;
    }

    .category {
      margin-bottom: 8px;

      h5 {
        cursor: pointer;
        display: inline;
      }
    }

    .question {
      margin: 8px;
      span {
        cursor: pointer;
      }
    }

    .answer {
      background: rgba(255, 255, 255, 0.9);

      p {
        margin-bottom: 0;
      }
    }
  }
}

.Answers {
  .answer {
    background: rgba(255, 255, 255, 0.7);
    min-height: 100%;
  }
}
