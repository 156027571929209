@import '../../variables.scss';

.MailingList {
  margin: 0 22px 0 21px;

  @media (max-width: 767.98px) {
    h2 {
      font-size: 1.6rem;
    }
  }
}
