.AddressAutocomplete {
  .autocomplete-dropdown {
    width: 100%;
    z-index: 1000;

    .suggestion {
      cursor: pointer;
    }

    .powered-by {
      bottom: 4px;
      right: 4px;
    }
  }
}
