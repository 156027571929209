@import '../../variables.scss';

.AboutMe {
  background: $color-blue;
  height: $footer-element-height;
  position: relative;
  transition: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  width: $footer-element-height;

  &:hover {
    background: darken($color-blue, 10%);
  }

  h1 {
    font-family: 'Pinyon Script', cursive;
    font-size: 3.5rem;
  }
}
