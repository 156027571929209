.subscription {
  .credit-card-image {
    height: 64px;

    img {
      height: 100%;
    }
  }
  .credit-card-details {
    line-height: 1em;

    p {
      margin-bottom: 8px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
