@import '../../variables.scss';

.Section {
  height: $section-height;

  &.blue {
    background: $color-blue;
  }
  &.green {
    background: $color-green;
  }
  &.yellow {
    background: $color-yellow;
  }

  .container {
    height: 100%;

    .title {
      height: 40px;
      margin: 30px 0 10px;
    }

    .row {
      height: 100%;

      .col,
      [class*='col-'] {
        height: 100%;

        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &.with-title {
    .container {
      .row {
        height: calc(100% - 80px);
      }
    }
  }
}
