@import '../../variables.scss';

.SocialMedia {
  height: $footer-element-height;
  position: relative;
  width: $footer-element-height;

  &:hover {
    img {
      opacity: 0.7;
    }

    .hover {
      .follow {
        top: -3rem;
        transition-delay: 0s;
      }
      .icons {
        opacity: 1;

        hr {
          margin-left: 40px;
          margin-right: 40px;
        }

        &,
        hr {
          transition-delay: 0.1s;
        }
      }
    }
  }

  img {
    height: 100%;
    margin-left: -80px;
    opacity: 0.8;
    transition: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
  }

  .hover {
    position: relative;
    width: 100%;

    .follow {
      font-size: 1.8rem;
      line-height: 1.8rem;
      position: absolute;
      top: -0.9rem;
      transition: all;
      transition-delay: 0.1s;
      transition-duration: 0.4s;
      transition-timing-function: ease-in-out;
      user-select: none;
      width: 100%;
    }

    .icons {
      font-size: 1.8rem;
      opacity: 0;
      position: absolute;
      width: 100%;

      hr {
        margin-left: 120px;
        margin-right: 120px;
      }

      a {
        color: white;

        &:hover {
          color: darken(white, 20%);
        }
      }

      &,
      hr {
        transition: all;
        transition-delay: 0s;
        transition-duration: 0.4s;
        transition-timing-function: ease-in-out;
      }
    }
  }
}
