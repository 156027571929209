@import '../variables.scss';

.FreeGift {
  .modal-content {
    background-image: url('../../images/free-gift.jpg');
    background-size: 100%;

    .modal-title {
      font-size: 2rem;
      width: 100%;
    }

    .free-gift-content {
      background: rgba($color-background, 0.6);
    }
  }
}
