.Header {
  height: 50px;

  .row {
    height: 100%;

    .col,
    [class*='col-'] {
      height: 100%;

      img {
        height: 100%;
      }
    }
  }
}
