$color-background: #0c3444;
$color-blue: #b3e0f1;
$color-green: #bde9c5;
$color-yellow: #fdf090;

$footer-element-height: 260px;
$logo-height: 700px;
$section-height: 500px;

.cover {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
