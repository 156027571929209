@import '../variables.scss';

.Account {
  .select {
    background: $color-green;
    cursor: pointer;
    left: 50%;
    margin-left: -125px;
    position: absolute;
    top: -1px;
    user-select: none;
    width: 250px;

    &:hover {
      background: darken($color-green, 10%);
    }
  }
  &.selected {
    background: darken(white, 10%);
  }
}
